import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { database, set, logEvent, analytics } from '../firebase'
import Input from '../components/Input/Input'
import Checkbox from '../components/Checkbox/Checkbox'
import Button from '../components/Button/Button'
import { Container, Flex, useToast } from '@chakra-ui/react'
import Base from './base'
import { useObject } from 'react-firebase-hooks/database'
import { ref, update } from 'firebase/database'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { isActive } from '../services/helpers'
import { v4 } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PhoneVerificationModal from '../components/NewGuest/PhoneVerificationModal/PhoneVerificationModal'
import { mappedCountryCodes } from '../utils/countryCodes'
import { phoneMasks } from '../utils/phoneMasks'
import { useMediaQuery } from '../hook/useMediaQuery'
import * as amplitude from '@amplitude/analytics-browser'
import RSVPEventDetailsView from '../components/RSVPEventDetailsView'
import EventCarouselView from '../components/EventCarouselView'

export const schema = yup
  .object({
    name: yup.string().required(),
    phoneNumber: yup.string().required(),
    countryCode: yup.object().required()
  })
  .required()

const NewGuest = ({ checkInvite }) => {
  const breakpoints = {
    sm: 600
  }

  const { sm } = useMediaQuery(breakpoints)

  const location = useLocation();
  const goingStatus = location.state?.going;  

  const { circleCode } = useParams()
  const [showPopUp, setShowPopUp] = useState(null)
  const [rsvpId, setRsvpId] = useState(null)
  const [sending, setSending] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()
  const [consent, setConsent] = useState(false)
  const [phoneToVerify, setPhoneToVerify] = useState(null)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const handleConsent = () => {
    setConsent(!consent)
  }

  const eventInfo = eventSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  const [allCountryCodes, setAllCountryCodes] = useState(mappedCountryCodes)

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    shouldUseNativeValidation: false
  })

  const [name, phoneNumber, countryCode] = form.watch([
    'name',
    'phoneNumber',
    'countryCode'
  ])

  const handleVerify = async () => {
    setSending(true)
    try {
      const phone = phoneNumber.startsWith('0')
        ? `${countryCode?.value}${phoneNumber.replace(/ /g, '').slice(1)}`
        : `${countryCode?.value}${phoneNumber.replace(/ /g, '')}`

      // Check if phone exists
      const guestList = Object.entries(guestsInfo).map(([gKey, gData]) => ({
        id: gKey,
        ...gData
      }))
      const existingGuest = guestList.find((x) => x.phone === phone)
      const id = existingGuest?.id ?? v4().replace(/-/g, '')

      if (!existingGuest) {
        await set(ref(database, `circles/${circleCode}/guests/${id}`), {
          id,
          name,
          phone
        })
      }

      setRsvpId(id)
      
      setPhoneToVerify(phone)
      setSending(false)
    } catch (e) {
      console.log('handleAddGuest', e)
      toast({
        status: 'error',
        title: e.message || 'Error while updating the guest list',
        position: 'top'
      })
      setSending(false)
    }
  }

  const handleSetResponse = async () => {
    try {
      form.setValue('name', '')
      form.setValue('phoneNumber', '')
      form.setValue('countryCode', '')      

      const hasRequestList = !!eventInfo?.extraCollab ?? false
      const hasMusic = !!eventInfo?.musicCollab ?? false

      const response = goingStatus ? 'yes' : 'no'

      await update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        response
      })

      logEvent(analytics, 'invite_response', {
        source: response
      })
      amplitude.track(
        'Invite responded to',
        {
          circleCode,
          response
        },
        {
          user_id: rsvpId
        }
      )
            
      if (!goingStatus) {        
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      } else {        
        if (hasRequestList || hasMusic) {
          console.log('new-guest: going gere')
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help`)
          return
        }
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      }
    } catch (err) {
      console.log('new-guest: error seting response', err)
    }
  }

  useEffect(() => {
    if (!eventLoading && !guestsLoading) {
      if (!eventSnap?.exists() || !isActive(eventInfo)) {
        navigate('/', { replace: true })
        return
      }
    }
  }, [
    eventSnap,
    guestsInfo,
    eventLoading,
    guestsLoading,
    eventInfo,
    navigate,
    circleCode
  ])

  useEffect(() => {
    amplitude.track('Invite viewed', {
      circleCode
    })
  }, [circleCode])

  const handlePhoneNumberMask = useMemo(() => {
    if (!countryCode) return

    const foundMask = phoneMasks.find((m) => m.value === countryCode?.value)

    if (foundMask) return foundMask.mask

    return '999999999999'
  }, [countryCode])

  const handleFilterCountryCodes = useCallback(
    (value) => {
      form?.setValue('countryCode', value)

      if (!value) {
        setAllCountryCodes(mappedCountryCodes)
        return
      }

      const filteredCountryCodes = mappedCountryCodes.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      )

      setAllCountryCodes(filteredCountryCodes)
    },
    [form]
  )

  if (eventLoading || guestsLoading)
    return (
      <Base
        hideBg={true}
        bottomBar={true}
        showPopUp={showPopUp}
        onChangePopUp={setShowPopUp}
        onSuccess={() => setShowPopUp(null)}
        onCloseLogin={() => setShowPopUp(null)}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Coteri - Respond to online invitation through invite link
          </title>
          <meta
            name="description"
            content="Enter your information to reply to the online invitation."
          />
        </Helmet>
        <Flex bottom="62px" left="0" right="0" position="fixed" zIndex="2">
          <Container
            bg="white"
            p="0"
            w="100%"
            maxW="800px"
            borderTopWidth="1px"
            borderColor="#EEE"
            boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
          >
            <Flex direction="column">
              <Flex
                bg="white"
                h="80px"
                align="center"
                justify="flex-end"
                px={{ base: '16px', md: '36px' }}
              />
            </Flex>
          </Container>
        </Flex>
      </Base>
    )

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      allowFullHeight={true}
      onCloseLogin={() => setShowPopUp(null)}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <div className="flex flex-1 flex-col md:items-stretch">
        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-2 flex-1">
            <RSVPEventDetailsView
              rsvpId={rsvpId}
              circleCode={circleCode}
              eventInfo={eventInfo}
              guestsInfo={guestsInfo}
            />
          </div>
          <div className="order-1 sm:w-[44%] w-[100%]">
            <EventCarouselView
              isGuest
              rsvpId={rsvpId}
              circleCode={circleCode}
              eventInfo={eventInfo}
              removeAttendingCard
            />
          </div>
        </div>

        <div className={'my-[15px]'}>
          <span>
            Confirm your contact information to reply to this event. You’ll
            receive an invitation via text.
          </span>

          <Input
            register={form.register('name')}
            form={form}
            type="default"
            placeholder="Name"
            className="mt-[15px] w-full"
            hasError={form.formState.errors.name?.message}
          />
          <div className="flex flex-row items-center mt-[15px] gap-5">
            <Input
              type={'searchable-select'}
              register={form.register('countryCode')}
              form={form}
              placeholder={sm ? 'Select your country code' : 'Country code'}
              options={allCountryCodes}
              className={'flex-[0.4]'}
              hasError={form.formState.errors.countryCode?.message}
              onChange={(v) => handleFilterCountryCodes(v.target.value)}
            />
            <Input
              register={form.register('phoneNumber')}
              form={form}
              type="masked"
              placeholder="Phone number"
              className="flex-1"
              hasError={form.formState.errors.phoneNumber?.message}
              mask={handlePhoneNumberMask}
            />
          </div>

          <Checkbox
            checked={consent}
            onClick={handleConsent}
            text="I consent to receive texts from Coteri."
            className={'mt-[10px]'}
          />
        </div>
      </div>

      <div className="flex items-center justify-between w-full md:w-auto">
        <Button
          type="text"
          size="medium"
          text={'Back'}
          onClick={() => navigate(-1)}
        />
        <Button
          text={'Continue'}
          size="medium"
          onClick={form.handleSubmit(handleVerify)}
          disabled={!consent || sending}
        />
      </div>

      {!!rsvpId && !!phoneToVerify ? (
        <PhoneVerificationModal
          circleCode={circleCode}
          rsvpId={rsvpId}
          phone={phoneToVerify}
          onClose={() => setPhoneToVerify(false)}
          onVerified={handleSetResponse}
        />
      ) : null}
    </Base>
  )
}

export default NewGuest
