import React, { memo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { analytics, auth, logEvent, signOut } from '../../../firebase'
import { ReactComponent as LogoSVG } from '../../../assets/icons/logo_cropped.svg'
import Button from '../../../components/Button/Button'
import { useMediaQuery } from '../../../hook/useMediaQuery'
import { ChevronRight } from '@mui/icons-material'
import LoginBox from '../../../components/LoginBox'
import RegisterBox from '../../../components/RegisterBox'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import { useNavigate, Link } from 'react-router-dom'

const Header = ({ isFaq }) => {
  const [burguer, setBurguer] = useState(false)
  const [isInitialMount, setIsInitialMount] = useState(true)
  const [showPopUp, setShowPopUp] = useState(null)
  const [canvaPopUp, setCanvaPopUp] = useState(null)
  const [redirect, setRedirect] = useState(null)

  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const breakpoints = {
    lg: 1024
  }

  const { lg } = useMediaQuery(breakpoints)

  const handleSignUp = () => {
    setShowPopUp('register')
  }

  const handleSignIn = () => {
    setShowPopUp('login')
  }

  const handleSignOut = async () => {
    await signOut(auth)
  }

  const handleLogOut = () => {
    setBurguer(false)
    logEvent(analytics, 'menu_clicked', {
      source: 'LOG_OUT'
    })
    handleSignOut()
  }

  const handleAboutUs = () => {
    logEvent(analytics, 'nav_bar_clicked', {
      source: 'ABOUT'
    })
    navigate('/about')
  }

  const handleSuccess = () => {
    setShowPopUp(null)
    if (!!redirect) {
      if (redirect.startsWith('canva=')) {
        setCanvaPopUp(redirect.substring(6, redirect.length - 1))
        return
      }
      navigate(redirect)
    }
  }

  const handleMyEvents = () => {
    logEvent(analytics, 'menu_clicked', {
      source: 'MY_EVENTS'
    })
    navigate('/event-history')
  }

  const handleSettings = () => {
    logEvent(analytics, 'nav_bar_clicked', {
      source: 'SETTINGS'
    })
    navigate('/settings')
  }

  const handleFAQ = () => {
    navigate('/faq')
  }

  const handleAnecdotes = () => {
    navigate('/anecdotes')
  }

  const handleHome = () => {
    navigate('/')
  }

  const handleBurguerClick = () => {
    setIsInitialMount(false)
    setBurguer(!burguer)
  }

  return (
    <div>
      <div
        id="header"
        className="w-[100%] h-28 flex items-center justify-between xl:px-44 sm:px-14 px-10 relative"
        style={{ zIndex: 10 }}
      >
        <Link to={'/'} style={{ zIndex: 10 }}>
          <LogoSVG className="w-[120px] h-[50px] lg:w-[160px] lg:h-[90px]" />
        </Link>

        <div
          className="hidden md:flex items-center gap-x-0 lg:gap-x-20 md:gap-x-4 md:flex-1 justify-center"
          style={{ zIndex: 10 }}
        >
          {/* <span
            className="text-white lg:text-[23px] text-[18px] hover:bg-secondary  px-4 py-2 rounded-full cursor-pointer transition duration-300 ease-in-out"
            onClick={
              !!user && !user?.isAnonymous ? handleMyEvents : handleAboutUs
            }
          >
            {!!user && !user?.isAnonymous ? 'My events' : 'About Us'}
          </span> */}
          {!!user && !user?.isAnonymous && <span
            className="text-white lg:text-[23px] text-[18px] hover:bg-secondary  px-4 py-2 rounded-full cursor-pointer transition duration-300 ease-in-out"
            onClick={handleMyEvents}
          >
            My events   
          </span>}
          <span
            className="text-white lg:text-[23px] text-[18px]  hover:bg-secondary  py-2 px-10 rounded-full cursor-pointer transition duration-300 ease-in-out"
            onClick={isFaq ? handleHome : handleFAQ}
          >
            {isFaq ? 'Home' : 'FAQ'}
          </span>
        </div>

        {!user ||
          (user.isAnonymous && (
            <div className="hidden md:flex items-center gap-x-5">
              <Button
                type="secondary"
                size={lg ? 'medium' : 'small'}
                text={'Sign up'}
                className={'bg-white text-black hover:text-white'}
                onClick={handleSignUp}
              />
              <Button
                type="border"
                size={lg ? 'medium' : 'small'}
                text={'Log in'}
                className={'border-white hover:border-secondary'}
                onClick={handleSignIn}
              />
            </div>
          ))}

        <div className="relative">
          <div
            id="bottomShower"
            className={`absolute w-[200px] rounded-b-lg sm:right-[55px] right-[0px] bg-terciary ${isInitialMount ? ' hidden top-0' : burguer ? 'slide-down-effect' : 'slide-up-effect'}`}
          >
            <div className="px-10 pb-4">
              {!!user && !user.isAnonymous && (
                <div
                  className="items-center justify-between mb-2 flex md:hidden cursor-pointer"
                  onClick={handleMyEvents}
                >
                  <span className="text-white text-[18px]">My events</span>
                  <ChevronRight htmlColor="white" />
                </div>
              )}
              <div
                className="items-center justify-between mb-2 flex md:hidden cursor-pointer"
                onClick={handleFAQ}
              >
                <span className="text-white text-[18px]">FAQ</span>
                <ChevronRight htmlColor="white" />
              </div>
              {/* {!user && (
                <div
                  className="items-center justify-between mb-2 flex md:hidden cursor-pointer"
                  onClick={handleAboutUs}
                >
                  <span className="text-white text-[18px]">About us</span>
                  <ChevronRight htmlColor="white" />
                </div>
              )} */}
              {!!user && !user.isAnonymous && (
                <div
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={handleSettings}
                >
                  <span className="text-white text-[18px]">My profile</span>
                  <ChevronRight htmlColor="white" />
                </div>
              )}

              {!!user && !user.isAnonymous ? (
                <div
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={handleLogOut}
                >
                  <span className="text-white text-[18px]">Log out</span>
                  <ChevronRight htmlColor="white" />
                </div>
              ) : (
                <>
                  <div
                    className="flex items-center justify-between mb-2  cursor-pointer"
                    onClick={handleSignIn}
                  >
                    <span className="text-white text-[18px]">Sign in</span>
                    <ChevronRight htmlColor="white" />
                  </div>
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={handleSignUp}
                  >
                    <span className="text-white text-[18px]">Sign up</span>
                    <ChevronRight htmlColor="white" />
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            id="burguer"
            className={`visible cursor-pointer w-[120px] lg:w-[160px] flex items-center justify-end sm:justify-center relative h-10`}
            onClick={handleBurguerClick}
            style={{ zIndex: 10 }}
          >
            <input type="checkbox" className="peer sr-only" checked={burguer} />
            <span
              className="w-6 h-[3px] block bg-white rounded-full transition-all duration-150 
              before:content-[''] before:absolute before:w-6 before:h-[3px] before:bg-white before:rounded-full before:-translate-y-2 before:transition-all before:duration-150
              after:content-[''] after:absolute after:w-6 after:h-[3px] after:bg-white after:rounded-full after:translate-y-2 after:transition-all after:duration-150
              peer-checked:bg-transparent peer-checked:before:translate-y-0 peer-checked:before:rotate-45 peer-checked:after:translate-y-0 peer-checked:after:-rotate-45"
            />
          </div>
        </div>

        <div className="absolute top-0 right-0 left-0 bottom-0 bg-terciary" />
      </div>

      <Modal
        isCentered
        isOpen={!!showPopUp}
        onClose={() => {
          if (showPopUp) {
            setShowPopUp(null)
          } else {
            setShowPopUp(null)
          }
        }}
      >
        <ModalOverlay />
        <ModalContent py="12px" mx="20px">
          <ModalCloseButton mt="4px" mr="0" />
          <ModalBody>
            {showPopUp === 'login' && (
              <LoginBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
            {showPopUp === 'register' && (
              <RegisterBox
                onSuccess={handleSuccess}
                onChangePopUp={setShowPopUp}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default memo(Header)
